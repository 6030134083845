Body {
  margin: 0px;
}

.ScrollTable .ContentCard--body {
  overflow-x: auto;
}

.wait, .wait *{
  cursor: wait !important;   
}